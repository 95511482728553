.root {
  position: relative;
}

.inner {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 456px;
  grid-gap: 1.5rem;
  padding: 1.625rem 12rem 2rem 0;
}

:global(.arLang) .inner {
  padding-right: 10rem;
  grid-gap: 12rem;
}

.posterContainer {
  display: flex;
  position: relative;
  height: auto;
  cursor: pointer;
}

.poster {
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.content {
  padding-top: 2.125rem;
  line-height: 1.55;
}

.description {
  white-space: pre-wrap;
  line-height: 1.6;
  margin-bottom: 0.9375rem;
}

.description b, .description strong {
  font-weight: 700;
}

.description b {
  color: #2975cc;
}

.description strong {
  color: #e6457a;
}

.posterContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  z-index: 1;
}

.title {
  color: #ffffff;
  font-size: 1.875rem;
  text-align: center;
  font-weight: 700;
}

.buttons {
  display: flex;
  grid-gap: 0.625rem;
}

.pipe {
  pointer-events: none;
  position: absolute;
  right: 80%;
  top: 75%;
  z-index: -1;
}

:global(.arLang) .pipe {
  right: 85%;
}

@media (max-width: 1160px) {
  .inner {
    grid-template-columns: 1fr 380px;
    padding-right: 8rem;
  }

  :global(.arLang) .inner {
    grid-gap: 7rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.875rem;
  }
}

@media (max-width: 992px) {
  .inner, :global(.arLang) .inner {
    grid-template-columns: 1fr;
    padding: 1rem;
    border: 1px solid #e1e3e6;
    border-radius: 16px;
    grid-gap: 0.5rem;
    margin-top: 2.25rem;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .content {
    padding: 0;
  }

  .posterContainer {
    order: -1;
  }

  .posterContent {
    grid-gap: 0.5rem;
  }

  .description {
    margin-top: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1.25rem;
    white-space: normal;
  }

  .pipe {
    display: none;
  }
}