.root {
  padding: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 4rem;
  z-index: 1000;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.15);
}

.logo {
  fill: #2c2d2e;
  height: 64px;
}

.icon {
  fill: none;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
}

.link {
  text-decoration: none;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 8px
}

.burgerButton {
  background: none;
  border: none;
  display: none;
  cursor: pointer;
}

.demoButton {
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 9px;
  height: 48px;
  padding: 0 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Gilmer';
}

.separator {
  display: none;
}

.demoButtonIcon {
  fill: white;
}

@media screen and (max-width: 900px) {
  .link {
    height: 64px;
  }

  .fullscreen {
    display: block;
    position: fixed;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .inner {
    display: block;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
  }

  .burgerButton {
    display: block;
    position: absolute;
    right: 16px;
    top: 20px;
  }

  .navigation {
    display: none;
    margin: 16px 0 0 0;
  }

  .navigation.visible {
    display: block;
  }
  .demoButton {
    width: 100%;
  }

  .separator {
    display: block;
    width: 100%;
    height: 1px;
    margin: 16px 0;
    background-color: #ccc;
  }
}
