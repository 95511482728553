.root {
  padding: 2.25rem 0 5.5rem 0;
}

.inner {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 456px;
  grid-gap: 2.5rem;
  padding: 1.625rem 1rem 1.75rem 1.5rem;
}

:global(.arLang) .inner {
  grid-gap: 11rem;
}

.posterContainer {
  display: flex;
  position: relative;
  height: auto;
  cursor: pointer;
}

.poster {
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.posterInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1.25rem;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content {
  padding-top: 1.75rem;
  line-height: 1.55;
}

.title {
  position: relative;
  color: #ffffff;
  text-align: center;
  font-size: 1.875rem;
  white-space: pre-wrap;
  line-height: 1.375;
  font-weight: 700;
}

.descriptionList {
  position: relative;
  white-space: pre-wrap;
  list-style-type: disc;
  padding-left: 1.375rem;
}

.descriptionList li + li {
  margin-top: 0.125rem;
}

.descriptionList li::marker {
  font-size: 0.5rem;
}

.description b, .description strong, .description span,
.descriptionList b, .descriptionList strong, .descriptionList span {
  font-weight: 700;
}

.description b,
.descriptionList b {
  color: #2975cc;
}

.description strong,
.descriptionList strong {
  color: #e6457a;
}

.description {
  line-height: 1.6;
  padding-left: 1.375rem;
  margin-top: 1rem;
  white-space: pre-wrap;
  margin-bottom: 0.875rem;
}

.buttons {
  display: flex;
  grid-gap: 0.5rem;
  padding-left: 1.5rem;
  margin-top: 0.9375rem;
}

@media (max-width: 1160px) {
  .root {
    padding-top: 2rem;
  }

  :global(.esLang) .descriptionList {
    max-width: 80%;
  }

  .title {
    font-size: 1.5rem;
  }

  .inner {
    grid-template-columns: 1fr 380px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (max-width: 992px) {
  .root {
    padding-bottom: 2rem;
  }

  :global(.esLang) .descriptionList {
    max-width: none;
  }

  .inner, :global(.arLang) .inner {
    grid-template-columns: 1fr;
    padding: 1rem;
    grid-gap: 0.5rem;
    border: 1px solid #e1e3e6;
    border-radius: 16px;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .content {
    padding: 0;
  }

  .description {
    white-space: normal;
    margin: 0;
  }

  .descriptionList {
    white-space: normal;
  }

  .pipe {
    display: none;
  }

  .posterInner {
    grid-gap: 0.5rem;
  }

  .posterContainer {
    order: -1;
  }
}