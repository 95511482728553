.inner {
  position: relative;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 450px 1fr;
  grid-gap: 5.75rem;
}

:global(.esLang) .inner {
  grid-gap: 2.5rem;
}

.posterContainer {
  position: relative;
  display: flex;
  cursor: pointer;
}

.poster {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.posterContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;
  z-index: 1;
}

.title {
  color: #ffffff;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
}

.buttons {
  display: flex;
  grid-gap: 0.5rem;
  margin-top: 1.125rem;
}

.description {
  white-space: pre-wrap;
  line-height: 1.6;
}

.description + .description {
  margin-top: 0.5rem;
}

.description b, .description strong {
  font-weight: 700;
}

.description b {
  color: #2975cc;
}

.description strong {
  color: #e6457a;
}

.pipe {
  pointer-events: none;
  position: absolute;
  left: 195px;
  bottom: 100%;
}

:global(.arLang) .pipe {
  bottom: 60%;
}

:global(.arLang) .pipe2 {
  width: 300px;
  top: 87%;
  left: 68%;
}

.pipe2 {
  pointer-events: none;
  position: absolute;
  left: 55%;
  top: 89%;
}

@media (max-width: 1160px) {
  .inner {
    grid-template-columns: 380px 1fr;
    grid-gap: 1.5rem;
  }

  :global(.esLang) .inner {
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-gap: 1rem;
  }

  :global(.esLang) .description {
    margin: 0;
  }

  :global(.esLang) .pipe2 {
    top: 98%;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.875rem;
    white-space: normal;
  }

  .pipe {
    width: 40px;
  }

  :global(.arLang) .pipe {
    width: 550px;
  }

  .pipe2 {
    width: 290px;
    top: 85%;
  }
}

@media (max-width: 992px) {
  .inner {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }

  .posterContent {
    grid-gap: 0.5rem;
  }

  .content {
    padding-bottom: 0;
  }

  .pipe, .pipe2 {
    display: none;
  }

  .title {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .description {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .buttons {
    position: relative;
    right: auto;
    bottom: auto;
    margin-top: 1.25rem;
  }
}