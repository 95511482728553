.root {
  display: inline-flex;
  align-items: center;
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  cursor: pointer;
  color: #ffffff;
  border: 0;
  grid-gap: 0.5rem;
  font-weight: 700;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 100px;
  background: linear-gradient(to right, #EB678F, #4986CC);
  z-index: 100;
  transition: bottom 600ms;
}

.root::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: #EB678F;
  animation-name: blink;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes blink {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
  }
  100% {
    transform: scale3d(1.1, 1.3, 1.1);
    opacity: 0;
  }
}

@media (max-width: 992px) {
  .root {
    padding: 0.75rem;
    right: 1rem;
    bottom: 1rem;
  }
}