.root {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  margin: auto;
  max-width: 1160px;
}

@media (max-width: 1160px) {
  .root {
    max-width: 960px;
  }
}

@media (max-width: 992px) {
  .root {
    max-width: 760px;
  }
}