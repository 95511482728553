.inner {
  display: grid;
  grid-template-columns: 1fr 335px;
  grid-gap: 1.5rem;
  overflow: auto;
  height: 100%;
  max-height: 90vh;
  padding: 0.75rem 1rem;
}

.close {
  display: inline-flex;
  position: absolute;
  top: -0.25rem;
  right: -3rem;
  padding: 0.5rem;
  color: #5d5f61;
  border-radius: 0.5rem;
  border: 0;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  background-color: #ffffff;
}

.videoResponsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.videoResponsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.actions {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  padding-top: 0.75rem;
  border-top: 1px solid #e1e3e6;
}

.right {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 0.5rem;
  overflow: auto;
}

.transcriptTitle {
  font-weight: 700;
  flex-shrink: 0;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e1e3e6;
}

.transcriptList {
  flex-grow: 1;
  font-size: 0.875rem;
  overflow: auto;
  display: flex;
  height: 100%;
  max-height: 550px;
  flex-direction: column;
  align-items: flex-start;
}

.transcriptList time {
  color: #454647;
  border-radius: 2rem;
  line-height: 1;
  padding: 0.3125rem 0.625rem;
  margin-bottom: 0.5rem;
  background-color: #ebedf0;
}

.transcriptList span {
  display: inline-flex;
  margin-bottom: 1.5rem;
}

.videoDescription {
  margin-top: 1rem;
}

.videoDescriptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.videoDescriptionTitleContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  grid-gap: 0.5rem;
}

.videoDescriptionTitle {
  font-size: 1.375rem;
  font-weight: 700;
}

.videoDescriptionTime {
  color: #909499;
  font-size: 0.875rem;
}

.videoDescriptionBody {
  font-size: 0.875rem;
}

.button {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.375rem;
  padding: 0.5rem;
  color: #4986cc;
  border-radius: 0.5rem;
  border: 1px solid #e1e3e6;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  background-color: #ffffff;
}

.button svg {
  flex-shrink: 0;
}

.buttonActive {
  color: #ffffff;
  background-color: #4986cc;
  border-color: #4986cc;
}

.videoDescriptionBody {
  white-space: pre-wrap;
}

.videoDescriptionBody b, .videoDescriptionBody strong, .videoDescriptionBody span {
  font-weight: 700;
}

.videoDescriptionBody b {
  color: #2975cc;
}

.videoDescriptionBody strong {
  color: #e6457a;
}

.videoDescriptionBody ul {
  margin-top: 0.5rem;
  list-style-type: disc;
  padding: 0 1.375rem;
}

.videoDescriptionBody ul li {
  margin: 0;
}

.videoDescriptionBody li + li {
  margin-top: 0.3125rem;
}

.videoDescriptionBody li::marker {
  font-size: 0.5rem;
}

.mobileTabs {
  display: none;
  border: 1px solid #c4c8cc;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.mobileTabItem {
  width: 50%;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  background-color: #ebedf0;
}

.mobileTabItem + .mobileTabItem {
  border-left: 1px solid #c4c8cc;
}

.mobileTabItemActive {
  background-color: #ffffff;
}

@media (max-width: 992px) {
  .inner {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
  }

  .close {
    right: 0;
    top: -0.25rem;
    padding: 0.25rem;
    transform: translateY(-100%);
  }

  .mobileTabs {
    display: flex;
  }

  .videoDescriptionBody, .right, .transcriptTitle {
    display: none;
  }

  .visibleTab {
    display: block;
  }
}