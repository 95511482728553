.list {
  margin-top: 1rem;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
  color: #0a0a0a;
  font-weight: 500;
  cursor: pointer;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  min-width: 250px;
  background-color: #f2f3f5;
  border-radius: 0.5rem;
}

.item + .item {
  margin-top: 0.5rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
}

.icon {
  fill: none;
}