.root {
  padding-top: 128px;
  padding-bottom: 128px;
  color: #ffffff;
  border-radius: 2rem 2rem 0 0;
  background-color: #2975CC;
}

.inner {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.sendIcon {
  fill: none;
}

.emailLink {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  font-weight: 500;
  font-size: 3rem;
  color: inherit;
}

.title {
  font-size: 3rem;
  font-weight: 500;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 1.25rem;
}

@media (max-width: 992px) {
  .root {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .inner {
    grid-template-columns: 1fr;
  }

  .emailLink {
    font-size: 1.75rem;
  }

  .title {
    font-size: 2rem;
  }
}