.inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, calc(33.333% - 1.3125rem));
  grid-gap: 2rem;
  padding: 7.25rem 0.3125rem;
  z-index: 1;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0.625rem 0.5rem 0.625rem;
  min-height: 886px;
  border-radius: 16px;
  background-clip: padding-box;
  background: #fff;
}

.itemWrap {
  padding: 5px;
  overflow: hidden;
}

.item::after {
  pointer-events: none;
  touch-action: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.item::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -5px;
  border-radius: 20px;
}

.item:nth-child(1)::before {
  background: linear-gradient(to bottom, #EB678F, #44E1EA);
}

.item:nth-child(1)::after {
  background: linear-gradient(to bottom, rgba(235, 103, 143, 0.15), rgba(68, 225, 234, 0.15));
}

.item:nth-child(1) .itemTitle {
  color: #8C1C9E;
}

:global(.arLang) .item:nth-child(3)::before {
  background: linear-gradient(to bottom, #EB678F, #44E1EA);
}

:global(.arLang) .item:nth-child(3)::after {
  background: linear-gradient(to bottom, rgba(235, 103, 143, 0.15), rgba(68, 225, 234, 0.15));
}

:global(.arLang) .item:nth-child(3) .itemTitle {
  color: #8C1C9E;
}

.item:nth-child(2)::before {
  background: linear-gradient(to right, #67EB84, #4986CC);
}

.item:nth-child(2)::after {
  background: linear-gradient(to bottom, rgb(103, 235, 132, 0.15), rgb(73, 134, 204, 0.15));
}

.item:nth-child(2) .itemTitle {
  color: #6EB019;
}

.item:nth-child(3)::before {
  background: linear-gradient(to right, #4986CC, #EB8F67);
}

.item:nth-child(3)::after {
  background: linear-gradient(to bottom, rgba(73, 134, 204, 0.15), rgba(235, 143, 103, 0.15));
}

.item:nth-child(3) .itemTitle {
  color: #B88232;
}

:global(.arLang) .item:nth-child(1)::before {
  background: linear-gradient(to right, #4986CC, #EB8F67);
}

:global(.arLang) .item:nth-child(1)::after {
  background: linear-gradient(to bottom, rgba(73, 134, 204, 0.15), rgba(235, 143, 103, 0.15));
}

:global(.arLang) .item:nth-child(1) .itemTitle {
  color: #B88232;
}

.itemTitle {
  font-size: 1.3125rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-align: center;
}

.itemFootnote {
  position: relative;
  text-align: center;
  margin: 0.25rem 0;
}

.itemFootnote span {
  font-weight: 700;
}

.itemTags {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.625rem;
  margin-top: 0.8125rem;
}

.itemTagsSmall {
  font-size: 0.875rem;
  grid-gap: 0.25rem;
}

.itemTagsCentered {
  justify-content: center;
}

.itemPosterContainer {
  position: relative;
  display: flex;
  cursor: pointer;
  margin: 2rem 0 1rem;
  color: #ffffff;
}

:global(.esLang) .itemPosterContainer {
  margin-top: 1.25rem;
}

.itemPoster {
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.itemPosterInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.itemPosterTitle {
  font-size: 2rem;
  font-weight: 700;
}

.itemDescription {
  position: relative;
  font-size: 0.875rem;
  max-width: 95%;
}

.itemDescription b, .itemDescription strong, .itemDescription span {
  font-weight: 700;
}

.itemDescription b {
  color: #2975cc;
}

.itemDescription strong {
  color: #e6457a;
}

.pipe {
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-51%);
}

.item:nth-child(1) .pipe {
  left: 59.5%;
}

.item:nth-child(3) .pipe {
  left: 40.2%;
}

.tabs {
  position: sticky;
  top: 0;
  display: none;
  padding: 0.75rem 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px 20px;
  z-index: 100;
  background-color: #ffffff;
}

.tabsHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  margin-bottom: 0.625rem;
  font-weight: 700;
}

.tabsHeader span {
  color: #4986CC;
}

.icon {
  fill: none;
}

.tabsList {
  display: flex;
  border: 2px solid #c4c8cc;
  border-radius: 8px;
  overflow: hidden;
}

.tabItem {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  width: 33.333%;
  color: #454647;
  padding: 0.5rem;
  background-color: #f2f3f5;
  z-index: 1;
}

.tabItemLetter {
  position: absolute;
  top: 50%;
  left: 0.25rem;
  font-weight: 500;
  font-size: 5.5rem;
  line-height: 1;
  color: #c4c8cc;
  transform: translateY(-53%);
  opacity: 0.3;
  z-index: -1;
}

.tabItem + .tabItem {
  border-left: 2px solid #c4c8cc;
}

:global(.arLang) .tabItem + .tabItem {
  border-left: 0;
  border-right: 2px solid #c4c8cc;
}

.tabItemActive {
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.15) -1px 0 15px 1px;
}

.tabItemActive .tabItemLetter {
  color: inherit;
}

.tabItem:nth-child(1).tabItemActive {
  color: #8C1C9E;
}

.tabItem:nth-child(2).tabItemActive {
  color: #6EB019;
}

.tabItem:nth-child(3).tabItemActive {
  color: #B88232;
}

.tabItemActive .tabItemLetter {
  color: inherit;
  opacity: 0.24;
}

@media (max-width: 1160px) {
  .inner {
    padding-top: 4.25rem;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, calc(33.333% - 0.625rem));
  }

  .item {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .itemTitle {
    font-size: 1.25rem;
  }

  .pipe {
    left: 59.5%;
  }

  .item:nth-child(1) .pipe {
    left: 47%;
  }

  .item:nth-child(3) .pipe {
    left: 72.25%;
  }

  .itemTagsSmall, .itemDescription {
    font-size: 0.8125rem;
  }

  .itemDescription {
    max-width: 100%;
  }

  .itemPosterTitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 992px) {
  .tabs {
    display: block;
  }

  .inner {
    grid-template-columns: 1fr;
    padding-bottom: 0;
  }

  .pipe {
    display: none;
  }

  .item {
    background-color: transparent;
    border: 1px solid #e1e3e6;
    border-radius: 24px;
  }

  .item, .item::before {
    display: none;
  }

  .item::after {
    border-radius: 24px;
  }

  .itemMobile {
    display: block;
  }

  .inner {
    padding-top: 2rem;
  }
}