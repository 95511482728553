.root {
  padding-top: 2.5rem;
}

.inner {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 455px 1fr;
  grid-gap: 1.625rem;
  padding-left: 18rem;
  padding-right: 2rem;
}

.posterContainer {
  display: flex;
  position: relative;
  height: auto;
  cursor: pointer;
}

.poster {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.posterInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content {
  padding-top: 2rem;
}

.title {
  color: #ffffff;
  text-align: center;
  font-size: 1.875rem;
  font-weight: 700;
}

.description {
  max-width: 85%;
  line-height: 1.5;
}

.description b, .description strong {
  font-weight: 700;
}

.description b {
  color: #2975cc;
}

.description strong {
  color: #e6457a;
}

.pipe, .pipe2, .pipe3 {
  pointer-events: none;
  position: absolute;
  right: 34.5%;
  bottom: 97%;
  z-index: -1;
}

.pipe {
  transform: translateX(-44px);
}

.pipe2 {
  bottom: 75%;
}

.pipe3 {
  transform: translateX(99.6%);
  z-index: -1;
}

.pipe4 {
  pointer-events: none;
  position: absolute;
  top: 88%;
  left: 52%;
}

.buttons {
  display: flex;
  grid-gap: 0.5rem;
  margin-top: 1rem;
}

@media (max-width: 1160px) {
  .inner {
    grid-template-columns: 380px 1fr;
    padding-left: 12rem;
    padding-right: 0;
    grid-gap: 1.25rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .pipe, .pipe2, .pipe3 {
    right: 14.7%;
  }

  .pipe4 {
    top: 85%  ;
    left: 56%;
  }
}

@media (max-width: 992px) {
  .root {
    padding-top: 2.5rem;
  }

  .inner {
    grid-template-columns: 1fr;
    padding: 1rem;
    grid-gap: 0.5rem;
    border: 1px solid #e1e3e6;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .description {
    max-width: none;
  }

  .pipe, .pipe2, .pipe3, .pipe4 {
    display: none;
  }

  .content {
    padding: 0;
  }

  .posterContainer {
    order: -1;
  }

  .posterInner {
    grid-gap: 0.5rem;
  }
}