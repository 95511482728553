.root {
  position: relative;
  padding-top: 4rem;
}

.wrapper {
  height: 100vh;
  overflow: auto;
}

.mainTitle {
  position: relative;
  font-weight: 900;
  font-size: 2.5rem;
  padding: 2.25rem 0 4.875rem 6.5rem;
  z-index: 1;
}

:global(.arLang) .mainTitle {
  padding-left: 0;
}

:global(.arLang) .mainTitle strong {
  margin-right: 0;
  margin-left: 0.5rem;
}

.demoButtonTop {
  bottom: calc(100% - 240px);
}

:global(.esLang) .mainTitle {
  font-size: 2.375rem;
  padding-left: 1rem;
}

.mainTitle strong {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  border-radius: 16px;
  background: linear-gradient(to bottom, rgba(172, 200, 232, 1), rgba(172, 200, 232, 0));
}

.topGradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 174px;
  z-index: -1;
}

.pipe {
  pointer-events: none;
  position: absolute;
  right: 225px;
  top: 0;
}

.pipeMobile {
  display: none;
  position: absolute;
  left: 15vw;
  top: -35px;
  z-index: -1;
}

@media (max-width: 992px) {
  .mainTitle, :global(.esLang) .mainTitle {
    padding: 1.125rem 0;
    line-height: 1.375;
    margin: auto;
    font-size: 1.875rem;
    font-weight: 500;
  }

  .mainTitle strong {
    margin: 0;
  }

  .pipeMobile {
    display: block;
  }

  .demoButtonTop {
    bottom: calc(100% - 260px);
  }
}
