.root {
  padding: 3rem 0;
  color: #ffffff;
  background-color: #205DA1;
}

.inner {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  grid-gap: 1.5rem;
}

.copyright {
  grid-area: 2/1;
}

.footnote {
  font-weight: 500;
  text-align: right;
}

.socials {
  margin-left: auto;
}

.footnote, .copyright {
  opacity: 0.72;
}

@media (max-width: 992px) {
  .inner {
    grid-template-columns: 1fr;
  }

  .footnote, .copyright {
    text-align: left;
  }

  .copyright {
    grid-area: 3/1;
  }

  .socials {
    grid-area: 2/1;
    margin-left: 0;
    text-decoration: underline;
  }
}