.root {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  min-width: 196px;
  font-weight: 700;
  cursor: pointer;
  color: #454647;
  font-size: 0.9375rem;
  padding: 6px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.72);
}


@media (max-width: 1160px) {
  .root {
    min-width: 160px;
  }

  .icon {
    width: 50px;
    height: 50px;
  }
}