.business-website-picker {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
}

.business-picker-button {
  box-sizing: border-box;
  height: 48px;
  padding: 0 8px;
  background-color: transparent;

  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #0a0a0a;
  cursor: pointer;
  justify-content: space-between;
}

.chevron-icon {
  margin: 1px 0 0 10px;
}

.picker-button:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.target-icon {
  height: 22px;
}

.target-icon.pax {
  margin-top: -2px;
}

.target-name {
  font-weight: 500;
  margin: 0 0 0 14px;
  white-space: nowrap;
}

.business-website-picker .language-list {
  position: absolute;
  left: 0;
  top: 64px;
  width: 206px;
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 5px 25px;
  z-index: 20;
  color: black;
}

.language-list-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.language-list-item:hover {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.language-list-item-title {
  font-weight: 400;
  margin-left: 12px;
}

.left-content {
  display: flex;
  align-items: center;
}

.chevron-icon, .plane-icon, .pax-icon {
  fill: white;
}

@media screen and (max-width: 900px) {
  .business-website-picker {
    display: block;
    margin: 0 0 16px 0;
  }

  .business-website-picker .business-picker-button {
    margin: 0;
  }
}
