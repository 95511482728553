.root {
  padding: 2rem;
  border: 1px solid #e1e3e6;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 992px) {
  .root {
    padding: 1rem;
    border-radius: 16px;
  }
}