.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  grid-gap: 0.5rem;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  font: inherit;
  font-weight: 500;
  min-width: 64px;
  line-height: 1.5;
  font-size: 0.875rem;
  padding: 0.25rem 0.375rem;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid transparent;
}

.default {
  color: #5d5f61;
  background-color: #ebedf0;
}

.dark {
  color: #ffffff;
  background-color: #454647;
}