.root {
  height: 48px;
  box-sizing: border-boxz;
}

.root :global(.Dropdown-option) {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.root :global(.Dropdown-menu .Dropdown-option) svg {
  display: none;
}

.menu {
  border-radius: 0.5rem;
}

.control {
  border-radius: 0.5rem;
  padding: 11px 44px 11px 10px;
  height: 48px;
  cursor: pointer;
}

.icon, .iconRotated {
  position: absolute;
  right: 11px;
  top: 11px;
  fill: none
}

.iconRotated {
  transform: rotate(180deg);
}

.placeholder {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  color: #0a0a0a;
  font-weight: 500;
}
