.root {
  position: relative;
  padding: 2.25rem 0;
}

.inner {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 455px;
  grid-gap: 1.5rem;
  padding-right: 18rem;
}

:global(.arLang) .inner {
  grid-gap: 7rem;
}

.bg {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.posterContainer {
  display: flex;
  position: relative;
  height: auto;
  cursor: pointer;
}

.poster {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.content {
  padding-top: 2.125rem;
  line-height: 1.5;
}

.description {
  margin-bottom: 0.275rem;
}

.posterContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;
  z-index: 1;
}

.title {
  color: #ffffff;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
}

.buttons {
  display: flex;
  grid-gap: 0.625rem;
  margin-top: 0.9375rem;
}

.description {
  white-space: pre-wrap;
}

.descriptionList {
  white-space: pre-wrap;
  list-style-type: disc;
}

.descriptionList li + li {
  margin-top: 0.3125rem;
}

.descriptionList li::marker {
  font-size: 0.5rem;
}

.description b, .description strong, .description span,
.descriptionList b, .descriptionList strong, .descriptionList span {
  font-weight: 700;
}

.description b,
.descriptionList b {
  color: #2975cc;
}

.description strong,
.descriptionList strong {
  color: #e6457a;
}

.pipe, .pipe2, .pipe3 {
  pointer-events: none;
  position: absolute;
  left: 34.5%;
  top: 97%;
}

.pipe {
  transform: translateX(-100%);
}

.pipe2 {
  top: 89%;
}

.pipe3 {
  transform: translateX(44px);
}

@media (max-width: 1160px) {
  .root {
    padding-top: 2rem;
  }

  .inner {
    grid-template-columns: 1fr 380px;
    padding-right: 13rem;
  }

  .content {
    padding-top: 1.5rem;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 992px) {
  .root {
    padding-top: 0;
  }

  .bg {
    display: none;
  }

  .inner {
    grid-template-columns: 1fr;
    padding: 1rem;
    border: 1px solid #e1e3e6;
    border-radius: 16px;
    margin-top: 2.25rem;
    background-color: rgba(255, 255, 255, 0.7);
  }

  :global(.arLang) .inner {
    grid-gap: 1rem;
  }

  .content {
    padding: 0;
  }

  .pipe, .pipe2, .pipe3 {
    display: none;
  }

  .posterContainer {
    order: -1;
  }

  .posterContent {
    grid-gap: 0.5rem;
  }

  .description {
    white-space: normal;
    margin: 0;
  }
}