html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'Gilmer';
  src: url('../assets/fonts/Gilmer-Regular.eot');
  src: local('Gilmer Regular'), local('Gilmer-Regular'),
  url('../assets/fonts/Gilmer-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Gilmer-Regular.woff') format('woff'),
  url('../assets/fonts/Gilmer-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilmer';
  src: url('../assets/fonts/Gilmer-Medium.eot');
  src: local('Gilmer Medium'), local('Gilmer-Medium'),
  url('../assets/fonts/Gilmer-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Gilmer-Medium.woff') format('woff'),
  url('../assets/fonts/Gilmer-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilmer';
  src: url('../assets/fonts/Gilmer-Bold.eot');
  src: local('Gilmer Bold'), local('Gilmer-Bold'),
  url('../assets/fonts/Gilmer-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Gilmer-Bold.woff') format('woff'),
  url('../assets/fonts/Gilmer-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilmer';
  src: url('../assets/fonts/Gilmer-Heavy.eot');
  src: local('Gilmer Heavy'), local('Gilmer-Heavy'),
  url('../assets/fonts/Gilmer-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Gilmer-Heavy.woff') format('woff'),
  url('../assets/fonts/Gilmer-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Gilmer', sans-serif;
  font-size: 16px;
  color: #0a0a0a;
  line-height: 1.4;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

svg {
  fill: currentColor;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
}

.text-blue {
  color: #2975cc;
}

.text-pink {
  color: #e6457a;
}

.font-medium {
  font-weight: 500;
}

.text-underline {
  text-decoration: underline;
}

#popup-root {
  z-index: 1010;
}

.popup-content {
  margin: auto;
  width: 1130px;
  padding: 0.75rem 1rem;
  max-width: 90vw;
  border-radius: 1rem;
  background: rgb(255, 255, 255);
  animation: anvil 200ms cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 290px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1010;
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.inner {
  display: grid;
  grid-template-columns: 1fr 400px;
}


@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .popup-overlay .popup-content {
    height: 85vh !important;
  }
}

@media (max-width: 576px) {
  .popup-overlay .popup-content {
    height: 76vh !important;
  }
}