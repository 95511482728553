.root {
  display: flex;
  grid-gap: 1rem;
}

.link {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
}